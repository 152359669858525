"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.adobeTrack = void 0;
// @ts-ignore
var isAdobeAvailable = function () { return !!window.__ANALYTICS_DATA__ && !!window._satellite; };
var defaultConfig = {
    pageName: document.title,
    pageType: 'Login',
    pageUrl: window.location.href,
};
var adobeTrack = function (config, tracker) {
    if (config === void 0) { config = {}; }
    if (tracker === void 0) { tracker = 'pageLoaded'; }
    if (isAdobeAvailable()) {
        // @ts-ignore
        window.__ANALYTICS_DATA__ = __assign(__assign({}, defaultConfig), config);
        // @ts-ignore
        window._satellite.track(tracker);
    }
};
exports.adobeTrack = adobeTrack;
