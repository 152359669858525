"use strict";
/**
 * Follows mobile first, media query will always query for min-width
 * Sizes are referencing react-bootstrap grid layout(inclusive for lower bound, exclusive for upper bound):
 * 0 - 576px : xs
 * 576px - 768px: sm
 * 768px - 992px: md
 * 992px - 1200px: lg
 * above 1200px: xl
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.media = exports.width = void 0;
var width = {
    xs: '0px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
};
exports.width = width;
var media = {
    xs: "(min-width: ".concat(width.xs, ")"),
    sm: "(min-width: ".concat(width.sm, ")"),
    md: "(min-width: ".concat(width.md, ")"),
    lg: "(min-width: ".concat(width.lg, ")"),
    xl: "(min-width: ".concat(width.xl, ")"),
};
exports.media = media;
