"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaginationTable = void 0;
require("./PaginationTable.scss");
var react_1 = __importStar(require("react"));
var react_table_1 = require("react-table");
var PaginationTable = function (props) {
    var tableUses = [
        props.useSort ? react_table_1.useSortBy : null,
        react_table_1.usePagination
    ];
    var _a = react_table_1.useTable.apply(void 0, __spreadArray([{
            columns: props.columns,
            data: props.data,
            // @ts-ignore
            pageCount: props.totalPageCount,
            manualPagination: true,
            manualSortBy: true,
            autoResetSortBy: false,
            autoResetPage: false
        }], (tableUses.filter(function (u) { return !!u; })), false)), getTableProps = _a.getTableProps, getTableBodyProps = _a.getTableBodyProps, headerGroups = _a.headerGroups, prepareRow = _a.prepareRow, page = _a.page, canPreviousPage = _a.canPreviousPage, canNextPage = _a.canNextPage, pageOptions = _a.pageOptions, pageCount = _a.pageCount, gotoPage = _a.gotoPage, nextPage = _a.nextPage, previousPage = _a.previousPage, setPageSize = _a.setPageSize, _b = _a.state, pageIndex = _b.pageIndex, pageSize = _b.pageSize, sortBy = _b.sortBy;
    (0, react_1.useEffect)(function () {
        props.fetchData && props.fetchData(pageSize, pageIndex, sortBy);
    }, [pageSize, pageIndex, sortBy]);
    var pageSizeOptions = [10, 20, 30, 40, 50];
    var handleItemSelected = function (e) {
        setPageSize(Number(e.detail.value));
    };
    var dropDownRef = (0, react_1.useRef)(null);
    var pageNumberRef = (0, react_1.useRef)(null);
    (0, react_1.useLayoutEffect)(function () {
        if (props.showPagination) {
            dropDownRef.current.addEventListener('itemSelect', handleItemSelected);
            pageNumberRef.current.addEventListener('input', handlePageNumberChanged);
            return function () {
                dropDownRef.current.removeEventListener('itemSelect', handleItemSelected);
                pageNumberRef.current.removeEventListener('input', handlePageNumberChanged);
            };
        }
    }, []);
    var handlePageNumberChanged = function (e) {
        var value = e.target.value;
        var page = value ? Number(value) - 1 : 0;
        gotoPage(page);
    };
    return (react_1.default.createElement("div", { className: "PaginationTable" },
        react_1.default.createElement("table", __assign({}, getTableProps()),
            react_1.default.createElement("thead", null, headerGroups.map(function (headerGroup) { return (react_1.default.createElement("tr", __assign({}, headerGroup.getHeaderGroupProps()), headerGroup.headers.map(function (column) { return (react_1.default.createElement("th", __assign({}, (props.useSort ? column.getHeaderProps(column.getSortByToggleProps()) : column.getHeaderProps()), { className: column.isSorted
                    ? column.isSortedDesc
                        ? "desc"
                        : "asc"
                    : "" }), column.render('Header'))); }))); })),
            react_1.default.createElement("tbody", __assign({}, getTableBodyProps()), page.map(function (row) {
                prepareRow(row);
                return (react_1.default.createElement("tr", __assign({}, row.getRowProps()), row.cells.map(function (cell) { return react_1.default.createElement("td", __assign({}, cell.getCellProps()), cell.render('Cell')); })));
            }))),
        props.showPagination ?
            react_1.default.createElement("div", { className: "pagination" },
                react_1.default.createElement("m-button", { onClick: function () { return gotoPage(0); }, disabled: !canPreviousPage }, '<<'),
                react_1.default.createElement("m-button", { onClick: function () { return previousPage(); }, disabled: !canPreviousPage }, '<'),
                react_1.default.createElement("span", null,
                    "Page: ",
                    react_1.default.createElement("strong", null,
                        pageIndex + 1,
                        " of ",
                        pageOptions.length)),
                react_1.default.createElement("m-button", { onClick: function () { return nextPage(); }, disabled: !canNextPage }, '>'),
                react_1.default.createElement("m-button", { onClick: function () { return gotoPage(pageCount - 1); }, disabled: !canNextPage }, '>>'),
                react_1.default.createElement("span", null, " | Go to page: "),
                react_1.default.createElement("m-text-field", { ref: pageNumberRef, value: pageIndex + 1, type: 'number' }),
                react_1.default.createElement("m-dropdown", { ref: dropDownRef, placeholder: pageSize }, pageSizeOptions.map(function (value) {
                    return (react_1.default.createElement("m-dropdown-item", { value: "".concat(value) }, value));
                })))
            : ''));
};
exports.PaginationTable = PaginationTable;
